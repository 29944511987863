<template>
  <section>
    <div class="header-page">
      <div class="container">
        <h1 data-aos="fade-down">Quem somos</h1>
      </div>
    </div>

    <div class="container page">
      <div class="text" data-aos="fade-up">
        <p>
          Somos apaixonados por tecnologia e educação, e acreditamos que essa
          combinação pode mudar o mundo. Com esse intuito nascemos em 2009 e
          desde então trabalhamos sob o desafio de transformar e potencializar a
          qualidade e a forma do ensino no Brasil. Nossos serviços têm por base
          a metodologia Blended Learning (Aprendizagem Híbrida), que une ensino
          presencial ao remoto, sem se limitar a um método ou modelo de
          aprendizagem. Assim, defendemos a diversidade, a pluralidade e a
          conectividade para estimular o gosto por aprender e ensinar.
        </p>
        <p>
          Somos pioneiros no mercado de gamificação educacional no país e
          oferecemos um AVA – Ambiente Virtual de Aprendizagem – responsivo,
          inovador e digital, acessível em qualquer dispositivo tecnológico com
          acesso à internet. Nosso AVA conta com tecnologias modernas, as quais
          podem ser personalizadas de acordo com a marca de cada cliente, o que
          explica mais de 200 mil membros espalhados por todo o país.
        </p>
        <p>
          Nossa tecnologia ecossistêmica une conceitos lúdicos e gamificados, e
          com incentivos educacionais, assim como promove diversas formas de
          interação e recompensas, sob a finalidade de estimular estudantes e
          educadores a se desafiarem e se desenvolverem com o avanço do mundo
          moderno. Além disso, priorizamos o trabalho humanizado com a
          colaboração de profissionais das mais diversas áreas, que assim como
          nós, acreditam que a educação deve ser acessível, democrática e
          construtora de um amanhã melhor para todos.
        </p>

        <div class="valores-empresa">
          <div class="valor-item">
            <h2>Nossa Missão:</h2>
            <p>Incentivar o prazer por aprender e ensinar.</p>
          </div>
          <div class="valor-item">
            <h2>Nossa Visão:</h2>
            <p>
              Ser a empresa referência em tecnologia educacional com inovação,
              gamificação e engajamento.
            </p>
          </div>
          <div class="valor-item">
            <h2>Nossos Valores:</h2>
            <p>
              Somos uma empresa movida por propósitos e assim trabalhamos com
              Sinergia, Superação, Dinamismo, Respeito e Equilíbrio com nossos
              clientes, parceiros e colaboradores.
            </p>
          </div>
        </div>
      </div>
      <div class="imagem">
        <img
          src="@/assets/img/quem-somos.png"
          alt="Quem somos"
          class="foto-page"
          data-aos="fade-left"
        />
        <img
          src="@/assets/icons/detalhe_01.svg"
          class="detalhe"
          data-aos="fade-right"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "quem-somos",
  created() {
    document.title = "Atheva | Quem somos";
  },
};
</script>

